import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import { withPrefix } from 'gatsby';
import TextPage, { TextBlock } from '../components/text-page';

const PoliciesAndManuals = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.policies_and_manuals}`,
    message: (
      <div>
        <div className="policies_divs_links">
          <a href={withPrefix("/advance_invoice_payment.pdf")} className="downloading_link" download>
            {messages.advance_invoice_payment_policy}
          </a>
        </div>
        <div className="policies_divs_links">
          <a href={withPrefix("/afpay_product_manual.pdf")} className="downloading_link" download>
            {messages.afpay_product_manual}
          </a>
        </div>
        <div className="policies_divs_links">
          <a href={withPrefix("/atm_cash_replenishment.pdf")} className="downloading_link" download>
            {messages.atm_cash_replishment_guideline}
          </a>
        </div>
        <div className="policies_divs_links">
          <a href={withPrefix("/reconciliation_and_settlement.pdf")} className="downloading_link" download>
            {messages.reconciliation_and_settlement_manual}
          </a>
        </div>
      </div>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.policies_and_manuals}`} textBlocks={textBlocks}/>);
};

export default PoliciesAndManuals;